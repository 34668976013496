<template>
<b-container fluid>
  <bo-page-title />

  <b-card-header v-if="isList">
    <div class="d-flex justify-content-between">
      <b-button-group>
        <b-button size="sm" @click="setActiveMenu('')" :variant="active_filter == '' ? 'info' : 'outline-info'">All ({{total_all}})</b-button>
        <b-button size="sm" @click="setActiveMenu('S')" :variant="active_filter == 'S' ? 'info' : 'outline-info'">Scheduled ({{total_schedule}})</b-button>
        <b-button size="sm" @click="setActiveMenu('R')" :variant="active_filter == 'R' ? 'info' : 'outline-info'">Running ({{total_running}})</b-button>
        <b-button size="sm" @click="setActiveMenu('F')" :variant="active_filter == 'F' ? 'info' : 'outline-info'">Finished ({{total_finish}})</b-button>
      </b-button-group>
    </div>
  </b-card-header>

  <b-card v-if="isList" no-body>
    <b-card-header>
      <b-row>
        <b-col md="3">
          <date-picker value-type="format" format="YYYY-MM-DD" range prefix-class="crm" v-model="dateFilter" @input="doFilterDate" v-b-tooltip.hover="'Filter by Date'" />
        </b-col>
        <b-col lg="3">
          <b-form-group>
            <v-select placeholer="Select Customer Type" label="label" :reduce="v => v.value" @input="doFilter()" v-model="filter.ct" :options="customerTypeFilter"></v-select>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group>
            <v-select placeholer="Select Prospect Source" label="label" :reduce="v => v.value" @input="doFilter()" v-model="filter.ps" :options="prospectSourceFilter"></v-select>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group>
            <v-select placeholer="Select Contact Media" label="label" :reduce="v => v.value" @input="doFilter()" v-model="filter.cm" :options="contactMediaFilter"></v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-end">
        <b-col md=4>
          <SearchInput :value.sync="filter.search" @search="doFilter" />
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body class="p-0">
      <b-table
        :fields="fields"
        :items="dataList||[]"
        :primary-key="idKey"
        :busy="!dataList"
        responsive
        show-empty
        bordered
        striped
      >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(number)="v">
          {{(data.per_page*(data.current_page-1))+v.index+1}}
        </template>
        <template #cell(tp_client_name)="data">
          <div><small>{{ data.item.tp_code }}</small></div>
          <div>{{ data.value }} - {{ data.item.tp_client_type == 'residencial' ? 'Residential' : 'Company' }}</div>
          <a v-if="data.item.tp_client_email" :href="'mailto: '+data.item.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ data.item.tp_client_email }}</span></a>
          <a v-if="data.item.tp_client_phone" :href="'tel: '+data.item.tp_client_phone" class="contact-info"><i class="icon-phone"></i><span>{{ data.item.tp_client_phone }}</span></a>
        </template>
        <template #cell(tp_status_customer)="data">
          <b-badge :variant="customerBadge[data.value].color">
            {{ customerBadge[data.value].word }}
          </b-badge>
          <template v-if="data.item.tss_id">
          <br>
          <small>Monitoring Date: {{ data.item.tss_date | moment('ll') }}</small>
          </template>
          <br>
          <b-badge variant="success" v-if="data.item.tp_finish_payment == 'Y'">Paid</b-badge>
          <b-badge variant="warning" v-else>Payment Ongoing</b-badge>
        </template>
        <template #cell(tp_start_date)="data">
          {{ data.value | moment('ll') }} <small>s.d.</small> {{ data.item.tp_end_date | moment('ll') }}
        </template>
        <template #cell(tp_contact_via)="data">
          {{ contactMedia(data.value).label }}
        </template>
        <template #cell(tp_total_price)="data">
          {{ formatRupiah(data.value) }}
        </template>
        <template #cell(action)="data">
          <div class="d-flex align-items-center" v-if="data.item.tp_status_customer == 'S'">
            <div class="align-items-center">
              <b-button
                class="btn-icon"
                v-if="moduleRole('update_payment') && data.item.tp_finish_payment != 'Y'"
                variant="danger"
                v-b-tooltip.hover="'Update Payment'"
                @click="openModalPayment(data.item)"
              >
                <i class="fas fa-dollar-sign"></i>
              </b-button>
              <b-button
                class="btn-icon"
                variant="outline-info"
                v-b-tooltip.hover="'View Detail'"
                :to="{ name: $route.name, params: { pageSlug: 'detail', pageId: data.item.tp_code } }"
              ><i class="fas fa-eye"></i>
              </b-button>
              <b-button
                class="btn-icon"
                variant="success"
                v-b-tooltip.hover="'Update to Running'"
                v-if="moduleRole('update_running')"
                @click="openModalRunning(data.item.tp_code)"
              >
                <i class="fas fa-cog"></i>
              </b-button>
              <b-button
                  class="btn-icon"
                  variant="outline-warning"
                  v-b-tooltip.hover="'Edit Customer'"
                  v-if="moduleRole('edit')"
                  @click="openModalEditCustomer(data.item)"
                ><i class="fas fa-pencil-alt"></i>
                </b-button>
            </div>
          </div>
          <template v-else-if="data.item.tp_status_customer == 'R'">
             <b-button
                class="btn-icon"
                variant="danger"
                v-if="moduleRole('update_payment') && data.item.tp_finish_payment != 'Y'"
                v-b-tooltip.hover="'Update Payment'"
                @click="openModalPayment(data.item)"
              >
                <i class="fas fa-dollar-sign"></i>
            </b-button>
            <b-button
              class="btn-icon"
              variant="outline-info"
              v-b-tooltip.hover="'View Detail'"
              :to="{ name: $route.name, params: { pageSlug: 'detail', pageId: data.item.tp_code } }"
            ><i class="fas fa-eye"></i>
            </b-button>
            <b-button
              class="btn-icon"
              variant="success"
              v-b-tooltip.hover="data.item.tss_id ? 'Update Monitoring Result' : 'Schedule Monitoring'"
              v-if="moduleRole('schedule_monitor')"
              @click="openModalMonitoring(data.item)"
            >
              <i class="fas fa-file-alt"></i>
            </b-button>
            <b-button
              v-if="moduleRole('finishing') && data.item.tp_finish_payment == 'Y'"
              class="btn-icon"
              variant="warning"
              v-b-tooltip.hover="'Finishing'"
              @click="finishing(data.item.tp_code)"
            >
              <i class="fas fa-check"></i>
            </b-button>
            <b-button
              class="btn-icon"
              variant="outline-warning"
              v-b-tooltip.hover="'Edit Customer'"
              v-if="moduleRole('edit')"
              @click="openModalEditCustomer(data.item)"
            ><i class="fas fa-pencil-alt"></i>
            </b-button>
          </template>
          <template v-else-if="data.item.tp_status_customer == 'F'">
            <b-button
              class="btn-icon"
              variant="outline-info"
              v-b-tooltip.hover="'View Prospect Details'"
              :to="{ name: $route.name, params: { pageSlug: 'detail', pageId: data.item.tp_code } }"
            ><i class="fas fa-eye"></i>
          </b-button>
          </template>
        </template>
      </b-table>
    </b-card-body>
    <b-card-footer>
      <template v-if="(dataList||[]).length&&data.total>10">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <label for="perPage">Show</label>
              <b-form-select
                size="sm"
                id="perPage"
                class="w-auto mx-50"
                v-model="perPage"
                :options="Config.dataShownOptions"
              />
            <label for="perPage">items per page</label>
          </div>
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </div>
      </template>
    </b-card-footer>

    <b-modal id="updatePayment"
      title="Update Payment"
      centered
      no-close-on-backdrop
      size="lg"
    >
      <b-row>
        <b-col md="6" lg="5">
          <b-form-row>
            <b-col md="6">
              <b-form-group label="Customer Name" label-for="cusName" label-class="mb-1">
                <span>{{dataModal.tp_client_name}}</span>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Type" label-for="cusType" label-class="mb-1">
                <span>{{dataModal.tp_client_type == 'residencial' ? 'Residential' : 'Company'}}</span>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Project Worth" label-for="cusPrice" label-class="mb-1">
                <span class="mr-1">{{ formatRupiah(dataModal.tp_total_price) }}</span>
                <div>
                  <b-badge variant="success" v-if="dataModal.paid == 'Y'">Paid</b-badge>
                  <b-badge variant="warning" v-else>Payment Ongoing</b-badge>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Remaining Payment" label-for="cusPrice" label-class="mb-1">
                <span class="mr-1">{{formatRupiah(dataModal.remaining)}}</span>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-button variant="outline-info" @click="payBreak = !payBreak">{{ payBreak ? 'Hide' : 'View' }} Payment History</b-button>
          <div class="border p-3 mt-3">
            <b-button variant="danger" @click="forcePayment(dataModal.tp_code)" size="sm">
              <i class="fas fa-flag mr-1"></i>
              Force Repayment
            </b-button>
            <b-form-text>Forcefully conclude project payment, even if it's not paid off yet.</b-form-text>
          </div>
        </b-col>
        <b-col md="6" lg="7">
          <validation-observer v-slot="{ handleSubmit }" ref="VFormPayment">
            <b-form @submit.prevent="handleSubmit(updatePayment())">
              <h5 class="font-weight-bold">Add Payment</h5>
              <b-form-group label="Amount" label-for="payAmount">
                <b-input-group prepend="Rp.">
                  <money id="payAmount" @input="setPricePartner(dataModal)" v-model="dataModal.tcp_amount" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                </b-input-group>
                <VValidate name="Amount" v-model="dataModal.tcp_amount" :rules="{ required: true, min_value: 100000, max_value: dataModal.remaining }" />
              </b-form-group>
              <b-form-group label="Payment Date" label-for="rescheduleDate">
                <date-picker type="date" value-type="format" format="YYYY-MM-DD" id="rescheduleDate" prefix-class="crm" v-model="dataModal.tcp_date" />
                <VValidate name="Payment Date" rules="required" v-model="dataModal.tcp_date" />
              </b-form-group>
              <b-form-group label="Source" label-for="source">
                <v-select placeholer="Select source" label="label" :reduce="v => v.value" v-model="dataModal.source" :options="sourceIncome"></v-select>
                <VValidate name="Source" rules="required" v-model="dataModal.source" />
              </b-form-group>
              <div class="border p-3 mb-3">
                <h5 class="font-weight-bold">Payment Shares</h5>
                <b-form-row>
                  <b-col md="6">
                    <b-form-group label="Company Share" label-for="proCompShare" class="mb-0">
                      <b-input-group prepend="Rp.">
                        <money id="proCompShare" @input="setPricePartner(dataModal)" v-model="dataModal.tcp_amount_bu" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                      </b-input-group>
                      <VValidate name="Company Share" v-model="dataModal.tcp_amount_bu" :rules="{ required: true, min_value: 0, max_value: dataModal.tcp_amount }" />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Partner Share" label-for="proPartShare" class="mb-0">
                      <b-input-group prepend="Rp.">
                        <money id="proPartShare" disabled v-model="dataModal.tcp_amount_partner" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                      </b-input-group>
                      <VValidate name="Partner Share" v-model="dataModal.tcp_amount_partner" :rules="{ required: true, min_value: 0, max_value: dataModal.tcp_amount }" />
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
        <b-col md="12">
          <b-form-group label="Payment History" label-for="cusPayBreak" v-show="payBreak">
            <b-table-simple small bordered class="payment-table">
              <b-thead>
                <b-tr>
                  <b-th>Type</b-th>
                  <b-th colspan="2">Amount</b-th>
                  <b-th>Paid On</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>

                <template v-for="(value, index) in dataModal.historyPayment">
                  <b-tr :key="'A' + index">
                    <b-td rowspan="2">{{ value.tcp_note == 'DP' ? 'Down Payment' : value.tcp_note }}</b-td>
                    <b-td colspan="2">{{ formatRupiah(value.tcp_amount) }}</b-td>
                    <b-td rowspan="2">{{ value.tcp_date | moment('MMM D') }}, {{ value.tcp_date | moment('YYYY') }}</b-td>
                  </b-tr>
                  <b-tr :key="'B' + index">
                    <b-td>
                      <b-form-group label="Company Share" label-for="comShare" label-class="mb-1" class="mb-0">
                        <span>{{ formatRupiah(value.tcp_amount_bu) }}</span>
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group label="Partner Share" label-for="comPartner" label-class="mb-1" class="mb-0">
                        <span>{{ formatRupiah(value.tcp_amount_partner) }}</span>
                      </b-form-group>
                    </b-td>
                  </b-tr>
                </template>
                <b-tr class="bg-info text-white">
                  <b-th colspan="2" class="text-right">Remaining Payment</b-th>
                  <b-td colspan="2">{{formatRupiah(dataModal.remaining)}}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="closeModal('updatePayment')">Cancel</b-button>
        <b-button size="md" variant="info" @click="updatePayment()">Submit Payment</b-button>
      </template>
    </b-modal>

    <b-modal
      id="monitoring"
      title="Schedule Monitoring"
      centered
    >
      <validation-observer v-slot="{ handleSubmit }" ref="VFormMonitoring">
        <b-form @submit.prevent="handleSubmit(addMonitoringResult())">
          <template v-if="dataModal.tss_id">
            <b-form-row>
              <b-col md="6">
                <b-form-group label="Monitoring Date" label-for="monDate" label-class="mb-1">
                  <span>{{dataModal.tss_date | moment('LLL')}}</span>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="PIC Monitoring" label-for="monPic" label-class="mb-1">
                  <span>{{dataModal.tss_pic_name}} - {{ dataModal.tss_monitor_type == 'workshop' ? 'To Workshop' : 'To Location' }}</span>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Note" label-for="monPic" label-class="mb-1">
                  <span>{{dataModal.tss_note || '-'}}</span>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="12">
                <b-form-group>
                  <b-form-checkbox v-model="dataModal.reschedule">Reschedule Monitoring ?</b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="dataModal.reschedule">
                <b-form-group label="Reschedule Date" label-for="rescheduleDate">
                  <date-picker type="datetime" value-type="format" format="YYYY-MM-DD HH:mm:ss" id="rescheduleDate" prefix-class="crm" v-model="dataModal.tss_reschedule_date" :disabled-date="notBeforeToday" />
                </b-form-group>
                <VValidate name="Reschedule Date" rules="required" v-model="dataModal.tss_reschedule_date" />
              </b-col>
            </b-form-row>
            <template v-if="!dataModal.reschedule">
              <b-form-group label="Monitoring Result" label-for="monResult">
                <b-form-textarea id="monResult" rows="3" placeholder="e.g. pemasangan sudah berjalan 50%" v-model="dataModal.tss_result" />
                <VValidate name="Monitoring Result" rules="required|min:5" v-model="dataModal.tss_result" />
              </b-form-group>
              <b-form-group label-for="monPhoto">
                <template #label>Photo <small>(Optional)</small></template>
                <Uploader v-model="dataModal.tss_image" :usePreview="true" type="survey_result"/>
              </b-form-group>
            </template>
            <b-form-checkbox v-if="dataModal.tss_id" v-model="dataModal.tss_next">Schedule Next Monitoring</b-form-checkbox>
          </template>
            
            <div v-if="!dataModal.tss_id || dataModal.tss_next">
              <hr v-if="dataModal.tss_id">
              <b-form-group label="PIC" label-for="surveyPic">
                <v-select id="surveyPic" v-model="dataModal.tss_pic" :options="mrSurveyor" label="label" :reduce="v => String(v.value)" placeholder="Choose a PIC for Monitoring" />
                <VValidate name="PIC" rules="required" v-model="dataModal.tss_pic" />
              </b-form-group>
              <b-form-group label="Monitoring Type" label-for="surveyPic">
                <b-form-radio-group :options="monitoringOption" v-model="dataModal.tss_monitor_type" />
                <VValidate name="Monitoring Type" v-model="dataModal.tss_monitor_type" rules="required" />
              </b-form-group>
              <b-form-group label="Monitoring Schedule" label-for="monSchedule">
                <date-picker type="datetime" id="monSchedule" value-type="format" format="YYYY-MM-DD HH:mm:ss" prefix-class="crm" v-model="dataModal.tss_next_date" :disabled-date="notBeforeToday"/>
                <VValidate name="Monitoring Schedule" v-model="dataModal.tss_next_date" rules="required" />
              </b-form-group>
              <b-form-group label="Note" label-for="proSurveyNote">
                <b-form-textarea id="proSurveyNote" rows="3" v-model="dataModal.tss_note" />
                <VValidate name="Note" rules="" v-model="dataModal.tss_note" />
              </b-form-group>
            </div>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="closeModal('monitoring')">Cancel</b-button>
        <b-button size="md" variant="info" @click="addMonitoringResult()">Submit Result</b-button>
      </template>
    </b-modal>

    <b-modal
      id="updateRunning"
      title="Update Running"
      centered
      no-close-on-backdrop
    >
      <validation-observer v-slot="{ handleSubmit }" ref="VFormRunning">
        <b-form @submit.prevent="handleSubmit(updateRunning())">
          <b-form-group label="PIC" label-for="surveyPic">
            <v-select id="surveyPic" v-model="dataModal.tss_pic" :options="mrSurveyor" label="label" :reduce="v => v.value" placeholder="Choose a PIC for Monitoring" />
            <VValidate name="PIC" rules="required" v-model="dataModal.tss_pic" />
          </b-form-group>
          <b-form-group label="Monitoring Type" label-for="surveyPic">
            <b-form-radio-group :options="monitoringOption" v-model="dataModal.tss_monitor_type" />
            <VValidate name="Monitoring Type" v-model="dataModal.tss_monitor_type" rules="required" />
          </b-form-group>
          <b-form-group label="Monitoring Schedule" label-for="monSchedule">
            <date-picker type="datetime" id="monSchedule" value-type="format" format="YYYY-MM-DD HH:mm:ss" prefix-class="crm" v-model="dataModal.tss_date" :disabled-date="notBeforeToday"/>
            <VValidate name="Monitoring Schedule" v-model="dataModal.tss_date" rules="required" />
          </b-form-group>
          <b-form-group label="Note" label-for="proSurveyNote">
            <b-form-textarea id="proSurveyNote" rows="3" v-model="dataModal.tss_note" />
            <VValidate name="Note" rules="" v-model="dataModal.tss_note" />
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="closeModal('updateRunning')">Cancel</b-button>
        <b-button size="md" variant="info" @click="updateRunning()">Update Running</b-button>
      </template>
    </b-modal>

    <b-modal
      id="editCustomer"
      title="Edit Customer"
      centered
      no-close-on-backdrop
    >
      <validation-observer v-slot="{ handleSubmit }" ref="VFormEditCustomer">
        <b-form @submit.prevent="handleSubmit(editCustomer())">
          <b-form-row>
            <b-col md=12 class="mb-4" v-if="dataModal.tp_finish_payment == 'Y'">
              <span>Project Worth : {{ formatRupiah(dataModal.tp_total_price) }}</span>
            </b-col>
            <b-col md=12 v-else>
              <b-form-group label="Project Worth" label-for="projWorth">
                <b-input-group prepend="Rp.">
                  <money id="projWorth" v-model="dataModal.tp_total_price" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                </b-input-group>
                <VValidate name="Project Worth" rules="required" v-model="dataModal.tp_total_price" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <b-form-checkbox v-model="dataModal.reschedule">Reschedule Project?</b-form-checkbox>
              </b-form-group>
            </b-col>
            <template v-if="dataModal.reschedule">
              <b-col md=6>
                <b-form-group label="Project Start Date" label-for="monSchedule">
                  <date-picker type="date" id="monSchedule" value-type="format" format="YYYY-MM-DD" prefix-class="crm" v-model="dataModal.tp_start_date" :disabled-date="notBeforeToday"/>
                  <VValidate name="Project Start Date" v-model="dataModal.tp_start_date" rules="required" />
                </b-form-group>
              </b-col>
              <b-col md=6>
                <b-form-group label="Project End Date " label-for="endDate">
                  <date-picker type="date" id="endDate" value-type="format" format="YYYY-MM-DD" prefix-class="crm" v-model="dataModal.tp_end_date" :disabled-date="notBeforeToday"/>
                  <VValidate name="Project End Date" v-model="dataModal.tp_end_date" rules="required" />
                </b-form-group>
              </b-col>
              <b-col md=12>
                <b-form-group label="Note Reschedule" label-for="proSurveyNote">
                  <b-form-textarea id="proSurveyNote" rows="3" v-model="dataModal.tp_note_reshcedule" />
                </b-form-group>
              </b-col>
            </template>
          </b-form-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="closeModal('editCustomer')">Cancel</b-button>
        <b-button size="md" variant="info" @click="editCustomer()">Update</b-button>
      </template>
    </b-modal>

    <b-modal
      id="refundPayment"
      title="Force Payment"
      centered
      no-close-on-backdrop
    >
      <validation-observer v-slot="{ handleSubmit }" ref="VFormEditCustomer">
        <b-form @submit.prevent="handleSubmit(doRefundPayment())">
          <b-form-row>
              <b-col md=12>
                <b-form-group>
                  <b-form-checkbox v-model="dataModal.is_refund">Refund Payment?</b-form-checkbox>
                </b-form-group>
              </b-col>
              <template v-if="dataModal.is_refund">
                <b-col md=12>
                  <b-form-group label="Refund Amount" label-for="amount">
                    <b-input-group prepend="Rp.">
                      <money id="amount" v-model="dataModal.refund" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                    </b-input-group>
                    <VValidate name="Refund Amount" :rules="{ required: true, min_value: 10000, max_value: dataModal.tp_dp}" v-model="dataModal.refund" />
                  </b-form-group>
                </b-col>
              </template>
          </b-form-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="closeModal('refundPayment')">Cancel</b-button>
        <b-button size="md" variant="info" @click="doRefundPayment()">Force</b-button>
      </template>
    </b-modal>
  </b-card>

  <template v-else>
    <Detail v-bind="dataDetail" />
  </template>
</b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'
import Detail from './Detail.vue'

export default {
  extends: GlobalVue,
  components:{Detail},
  data() {
    return {
      dateFilter: [],
      sourceIncome:[ 
        { label: 'Client', value: 'INC'},
        { label: 'Internal', value: 'EXP'}
      ],
      idKey:'tp_id',
      mrValidation: {},
      statusSurveyResult: [
        { text: 'Closing', value: 'C' },
        { text: 'Reject', value: 'R' },
      ],
      monitoringOption: [
        { text: 'To Workshop', value: 'workshop' },
        { text: 'To Location', value: 'location' },
      ],
      statusPending: [
        { text: 'Survey', value: 'S' },
        { text: 'Closing', value: 'C' },
        { text: 'Reject', value: 'R' },
      ],
      closingStatus: [
        { text: 'Schedule', value: 'S' },
        { text: 'Running', value: 'R' },
      ],
      fields: [
        {
          key: 'number', label: '#', 
        },
        {
          key: 'tp_client_name', label: 'Customer Name',
        },
        {
          key: 'tp_start_date', label: 'Project Schedule',
        },
        {
          key: 'tp_client_address', label: 'Address', 
        },
        {
          key: 'tp_keperluan', label: 'Purpose', 
        },
        {
          key: 'tp_total_price', label: 'Project Worth', 
        },
        {
          key: 'mp_name', label: 'Partner', 
        },
        {
          key: 'tp_status_customer', label: 'Status', 
          },
        {
          key: 'action', 
        },
      ],
      customerExists: false,
      mrClient: [],
      mrPartner: [],
      mrSurveyor: [],
      customerName: '',
      dataModal: {},
      total_all: 0,
      total_schedule: 0,
      total_running: 0,
      total_finish: 0,
      active_filter: '',
      dataDetail: {},
      payBreak: null
    }
  },
  mounted(){
    this.apiGet()
    this.active_filter = this.$route.query.status || ''
    this.$set(this.row, 'tp_status_prospect', 'FU')

    const a = []
    a[0] = this.$route.query['start-date'] || null
    a[1] = this.$route.query['end-date'] || null

    this.$set(this, 'dateFilter', a)
  },
  methods: {
    doFilterDate(newVal){
      this.$set(this.filter, 'start-date', newVal[0] || '')
      this.$set(this.filter, 'end-date', newVal[1] || '')
      this.doFilter()
    },
    openModalEditCustomer(data){
      this.$set(this, 'dataModal', {})
      this.$set(this, 'dataModal', Object.assign({}, data))

      this.$bvModal.show('editCustomer')
    },
    editCustomer(){
      this.$refs.VFormEditCustomer.validate().then(success => {
        if(!success) return 

        this.$swal({
          title: 'Update Customer...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: 'update-customer', ...this.dataModal} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.$bvModal.hide('editCustomer')
              })
          }
        }).catch(() => {
            this.apiGet()
            this.$bvModal.hide('editCustomer')
        })        
      })
    },
    finishing(code){

      this.$swal({
        title: 'Are you sure to finish the project?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Sure',
        icon: 'question',
      }).then(result => {
        if(result.value){
          Gen.apiRest(
            '/do/' + this.modulePage,
            { data: {type: 'finishing', code} },
            'POST'
          ).then(res => {
            if(res){
                this.$swal({
                  title: res.data.message,
                  timer: 1500,
                  icon: 'success',
                }).then(() => {
                  this.apiGet()
                  this.$bvModal.hide('updatePayment')
                })
            }
          }).catch(() => {
              this.apiGet()
              this.$bvModal.hide('updatePayment')
          })
        }
      })

    },
    openModalRunning(code){
      this.$swal({
        title: 'Update status to Running?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then(result => {
        if(result.value){
          Gen.apiRest(
              '/do/' + this.modulePage,
              { data: {type: 'update-running', code} },
              'POST'
            ).then(res => {
              if(res){
                  this.$swal({
                    title: res.data.message,
                    text: '',
                    timer: 1500,
                    icon: 'success',
                  }).then(() => {
                    this.apiGet()
                  })
              }
            }).catch(() => {
              this.apiGet()
            })
        }
      })
    },
    openModalMonitoring(data){
      this.$set(this, 'dataModal', {})
      this.$set(this, 'dataModal', data)

      this.$bvModal.show('monitoring')
    },
    updateRunning(){
      this.$refs.VFormRunning.validate().then(success => {
        if(!success) return
          Gen.apiRest(
              '/do/' + this.modulePage,
              { data: {type: 'updateRunning', ...this.dataModal} },
              'POST'
            ).then(res => {
            if(res){
                this.$swal({
                  title: res.data.message,
                  text: '',
                  timer: 1500,
                  icon: 'success',
                }).then(() => {
                  this.$bvModal.hide('updateRunning')
                  this.apiGet()
                })
            }
          }).catch(() => {
            this.$bvModal.hide('updateRunning')
            this.apiGet()
          })
      })
    },
    openModalPayment(data){
      Gen.apiRest('/get/' + this.modulePage + '/payment', 
        { params: { code: data.tp_code } }
      )
      .then(res => {

        data = Object.assign(data, res.data)
        this.$set(this, 'dataModal', data)

        this.$bvModal.show('updatePayment')
      })
    },
    setActiveMenu(status){
      this.active_filter = status

      this.$set(this.filter, 'status', status)
      this.doFilter()
    },
    notBeforeToday(date) {
      return date ?  false : false
      // return date < new Date(new Date().setHours(0, 0, 0, 0))
    },
    closeModal(id, resetRow = true){
      this.$bvModal.hide(id)
      if(resetRow){
        this.$set(this, 'row', {})
      }
    },
    updatePayment(){
      this.$refs.VFormPayment.validate().then(success => {
        if(!success) return

        this.$swal({
          title: 'Submit Payment...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: 'update-payment', ...this.dataModal} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.$bvModal.hide('updatePayment')
              })
          }
        }).catch(() => {
            this.apiGet()
            this.$bvModal.hide('updatePayment')
        })
      })
    },
    forcePayment(){
      return this.$bvModal.show('refundPayment')
    },
    refundPayment(){
      this.$set(this.dataModal, 'refund', 0)
      this.$bvModal.show('refundPayment')
    },
    doRefundPayment(){
      this.$swal({
        title: 'Are you sure to force payment?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Sure',
        icon: 'question',
      }).then(result => {
        if(result.value){
          Gen.apiRest(
            '/do/' + this.modulePage,
            { data: {type: 'forcepayment', code: this.dataModal.tp_code, refund: this.dataModal.refund, is_refund: !!this.dataModal.is_refund } },
            'POST'
          ).then(res => {
            if(res){
                this.$swal({
                  title: res.data.message,
                  timer: 1500,
                  icon: 'success',
                }).then(() => {
                  this.apiGet()
                  this.$bvModal.hide('refundPayment')
                  this.$bvModal.hide('updatePayment')
                })
            }
          }).catch(() => {
              this.apiGet()
              this.$bvModal.hide('refundPayment')
              this.$bvModal.hide('updatePayment')
          })
        }
      })
    },
    addMonitoringResult(){
      this.$refs.VFormMonitoring.validate().then(success => {
        if(!success) return

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: 'update-monitoring', ...this.dataModal} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.$bvModal.hide('monitoring')
              })
          }
        }).catch(() => {
            this.apiGet()
            this.$bvModal.hide('monitoring')
        })
      })
    },
    setPricePartner(data){
      const hasil = data['tcp_amount'] - data['tcp_amount_bu']
      data['tcp_amount_partner'] = hasil 
    },
  },
  computed: {
  },
  watch:{
    $route(){
      this.apiGet()
    },
    'filter'(){
      if('ct' in this.filter == false){
        this.$set(this.filter, 'ct', '')
      }
      if('ps' in this.filter == false){
        this.$set(this.filter, 'ps', '')
      }
      if('cm' in this.filter == false){
        this.$set(this.filter, 'cm', '')
      }

      const a = []
      a[0] = this.filter['start-date'] || null
      a[1] = this.filter['end-date'] || null
      this.$set(this, 'dateFilter', a)
    },
    customerExists(newVal){
      if(!newVal){
          this.$set(this.row, 'tp_client_name', '')
          this.$set(this.row, 'tp_client_type', '')
          this.$set(this.row, 'tp_client_phone', '')
          this.$set(this.row, 'tp_client_email', '')
          this.$set(this.row, 'tp_client_address', '')
      }
      this.$set(this.row, 'customer_exist', newVal)
    },
    customerName(newVal){
      this.$set(this.row, 'tp_client_name', this.mrClient.find(v => v.mc_id == newVal).mc_name)
      if(!this.customerExists) return

      const cust = this.mrClient.find(v => v.mc_id == this.customerName)
      if(cust){
        this.$set(this.row, 'tp_client_type', cust.mc_type)
        this.$set(this.row, 'tp_client_phone', cust.mc_phone)
        this.$set(this.row, 'tp_client_email', cust.mc_email)
        this.$set(this.row, 'tp_client_address', cust.mc_address)
      }
    },
  }
}
</script>