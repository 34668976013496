<template>
  <section id="prospect">
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Customer Information">
          <b-row>
            <b-col md="5" class="border-right">
              <b-row>
                <b-col md="8" lg="6">
                  <div class="detail-info">
                    <span>Customer Name</span>
                    <h4>{{customerInformation.tp_client_name || '-'}} - {{ customerInformation.tp_client_type == 'residencial' ? 'Residential' : 'Company' }}</h4>
                  </div>
                  <div class="detail-info" v-if="customerInformation.tp_client_type == 'company'">
                    <span>Company Name</span>
                    <h4>{{customerInformation.tp_client_company || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Contact</span>
                    <h4 v-if="customerInformation.tp_client_email"><a :href="'mailto:' + customerInformation.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ customerInformation.tp_client_email || '-'}}</span></a></h4>
                    <h4 v-if="customerInformation.tp_client_phone"><a :href="'tel:' + customerInformation.tp_client_phone" class="contact-info"><i class="icon-phone"></i><span>{{ customerInformation.tp_client_phone || '-'}}</span></a></h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Address</span>
                    <h4>{{ customerInformation.tp_client_address || '-' }}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Source</span>
                    <h4>{{ $parent.prospectSrc(customerInformation.tp_source).label || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Contact Via</span>
                    <h4>{{ $parent.contactMedia(customerInformation.tp_contact_via).label || '-'}}</h4>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="5">
              <b-row>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Purpose</span>
                    <h4>{{ customerInformation.tp_keperluan || '-' }}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>General Information</span>
                    <h4>{{customerInformation.tp_general_information || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Partner</span>
                    <h4>{{customerInformation.mp_name || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Project Schedule</span>
                    <h4>{{customerInformation.tp_start_date | moment('LL')}} <small>s.d.</small> {{ customerInformation.tp_end_date | moment('LL') }}</h4>
                  </div>
                </b-col>
                <b-col md="8" v-if="customerInformation.tp_status_prospect == 'P'">
                  <div class="detail-info">
                    <span>Pending Reason</span>
                    <h4>{{customerInformation.tp_pending_reason || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8" v-else-if="customerInformation.tp_status_prospect == 'R'">
                  <div class="detail-info">
                    <span>Reject Reason</span>
                    <h4>{{customerInformation.tp_reject_reason || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Status</span>
                    <div>
                      <b-badge :variant="$parent.customerBadge[customerInformation.tp_status_customer].color">
                        {{ $parent.customerBadge[customerInformation.tp_status_customer].word }}
                      </b-badge>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Follow Up History">
          <template v-if="followUpHistory.length">
            <b-row>
              <b-col lg="8" md="10">
                <div class="timeline">
                  <div class="timeline-box" v-for="(value, key) in followUpHistory" :key="key">
                    <div class="timeline-head">
                      <h4 class="timeline__title">{{ value.tfu_date | moment('LL') }}</h4>
                      <b-badge v-if="value.tfu_status_done == 'done'" :variant="$parent.prospectBadge[value.tfu_status].color">
                        {{ $parent.prospectBadge[value.tfu_status].word }}
                      </b-badge>
                      <p v-else>Not Finished yet</p>
                      <span v-if="value.tfu_status_done == 'done'">Followed Up by: {{ value.tfu_person }}</span>
                    </div>
                    <div class="timeline-body">
                      <p>{{ value.tfu_result }}</p>
                      <p v-if="value.tfu_pending_reason">Pending Reason: {{ value.tfu_pending_reason }}</p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <h3>Follow Up History is Empty.</h3>
          </template>
        </b-tab>
        <b-tab title="Survey History">
          <template v-if="surveyHistory.length">
            <b-row>
              <b-col lg="6" md="8">
                <div class="detail-info">
                  <span>Survey Address</span>
                  <h4>{{ (surveyHistory[0] || {tss_address: ''}).tss_address }}</h4>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8" md="10">
                <div class="timeline">
                  <div class="timeline-box" v-for="(value, index) in surveyHistory" :key="index">
                    <div class="timeline-head">
                      <h4 class="timeline__title">{{ value.tss_date | moment('LLL') }}</h4>
                      <b-button v-if="value.tss_image" size="sm" variant="outline-info" @click="setLightBox(value.tss_image)">
                        <i class="fas fa-image"></i>
                        See Photo
                      </b-button>
                      <b-button
                        class="ml-2 btn-icon"
                        variant="outline-warning"
                        v-b-tooltip.hover="'Edit Photo'"
                        v-if="value.tss_image"
                        @click="showUpdateImage(value.tss_image, value.tss_id)"
                        size="sm"
                      ><i class="fas fa-pencil-alt"></i>
                      </b-button>
                      <p>Surveyor: {{ value.ms_name }}</p>
                    </div>
                    <div class="timeline-body">
                      <p>{{value.tss_result}}</p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <h3>Survey History is Empty.</h3>
          </template>
        </b-tab>
       <b-tab title="Payment">
          <b-row>
            <b-col md="6" lg="5" v-if="customerInformation.tp_status_customer != 'F' && customerInformation.tp_finish_payment != 'Y'">
              <template v-if="$parent.moduleRole('update_payment')">
                <validation-observer v-if="customerInformation.tp_finish_payment != 'Y'" mode="passive" v-slot="{ handleSubmit }" ref="VFormUpdatePayment">
                  <b-form @submit.prevent="handleSubmit(updatePayment())">
                    <b-form-row>
                      <b-col md="6">
                        <div class="detail-info">
                          <span>Project Worth</span>
                          <h4>{{$parent.formatRupiah(customerInformation.tp_total_price)}}</h4>
                          <div>
                            <b-badge variant="success" v-if="customerInformation.tp_finish_payment == 'Y'">Paid</b-badge>
                            <b-badge variant="warning" v-else>Payment Ongoing</b-badge>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="6">
                        <div class="detail-info">
                          <span>Remaining Payment</span>
                          <h4>{{$parent.formatRupiah(remaining)}}</h4>
                        </div>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group label="Amount" label-for="payAmount">
                          <b-input-group prepend="Rp.">
                            <money id="payAmount" @input="setPricePartner(dataModal)" v-model="dataModal.tcp_amount" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                          </b-input-group>
                          <VValidate mode="passive" name="Amount" v-model="dataModal.tcp_amount" :rules="{ required: true, min_value: 100000, max_value: remaining }" />
                        </b-form-group>
                        <b-form-group label="Payment Date" label-for="rescheduleDate">
                          <date-picker type="date" value-type="format" format="YYYY-MM-DD" id="rescheduleDate" prefix-class="crm" v-model="dataModal.tcp_date" />
                          <VValidate name="Payment Date" rules="required" v-model="dataModal.tcp_date" />
                        </b-form-group>
                        <b-form-group label="Source" label-for="source">
                          <v-select placeholer="Select source" label="label" :reduce="v => v.value" v-model="dataModal.source" :options="$parent.sourceIncome"></v-select>
                          <VValidate name="Source" rules="required" v-model="dataModal.source" />
                        </b-form-group>
                        <div class="border p-3 mb-3">
                          <h5 class="font-weight-bold">Payment Shares</h5>
                          <b-form-row>
                            <b-col md="6">
                              <b-form-group label="Company Share" label-for="proCompShare" class="mb-0">
                                <b-input-group prepend="Rp.">
                                  <money id="proCompShare" @input="setPricePartner(dataModal)" v-model="dataModal.tcp_amount_bu" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                                </b-input-group>
                                <VValidate mode="passive" name="Company Share" v-model="dataModal.tcp_amount_bu" :rules="{ required: true, min_value: 0, max_value: dataModal.tcp_amount }" />
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Partner Share" label-for="proPartShare" class="mb-0">
                                <b-input-group prepend="Rp.">
                                  <money id="proPartShare" disabled v-model="dataModal.tcp_amount_partner" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                                </b-input-group>
                                <VValidate mode="passive" name="Partner Share" v-model="dataModal.tcp_amount_partner" :rules="{ required: true, min_value: 0, max_value: dataModal.tcp_amount }" />
                              </b-form-group>
                            </b-col>
                          </b-form-row>
                        </div>
                      </b-col>
                      <b-col md="12">
                        <b-button type="submit" variant="info btn-rounded">Submit Payment</b-button>
                      </b-col>
                    </b-form-row>
                  </b-form>
                </validation-observer>
              </template>
            </b-col>
            <b-col md="8" v-else>
              <b-form-row>
                <b-col md="4">
                  <div class="detail-info">
                    <span>Project Worth</span>
                    <h4>{{$parent.formatRupiah(customerInformation.tp_total_price)}}</h4>
                    <div>
                      <b-badge variant="success" v-if="customerInformation.tp_finish_payment == 'Y'">Paid</b-badge>
                      <b-badge variant="warning" v-else>Payment Ongoing</b-badge>
                    </div>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="detail-info">
                    <span>Remaining Payment</span>
                    <h4>{{$parent.formatRupiah(remaining)}}</h4>
                  </div>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col md="6" lg="7">
              <b-row>
                <b-col md=12>
                  <div class="detail-info">
                    <span>Payment History</span>
                    <b-table-simple small bordered class="payment-table">
                      <b-thead>
                        <b-tr>
                          <b-th>Type</b-th>
                          <b-th colspan="2">Amount</b-th>
                          <b-th>Paid On</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                      <template v-for="(value, index) in historyPayment">
                          <b-tr :key="'A' + index">
                            <b-td rowspan="2">{{ value.tcp_note == 'DP' ? 'Down Payment' : value.tcp_note }}</b-td>
                            <b-td colspan="2"> {{value.tcp_type == 'EXP' ? '-' : ''}} {{ $parent.formatRupiah(value.tcp_amount) }}</b-td>
                            <b-td rowspan="2">{{ value.tcp_date | moment('MMM D') }}, {{ value.tcp_date | moment('YYYY') }}</b-td>
                          </b-tr>
                          <b-tr :key="'B' + index">
                            <template v-if="value.tcp_note != 'REFUND'">
                              <b-td>
                                <b-form-group label="Company Share" label-for="comShare" label-class="mb-1" class="mb-0">
                                  <span>{{ $parent.formatRupiah(value.tcp_amount_bu) }}</span>
                                </b-form-group>
                              </b-td>
                              <b-td>
                                <b-form-group label="Partner Share" label-for="comPartner" label-class="mb-1" class="mb-0">
                                  <span>{{ $parent.formatRupiah(value.tcp_amount_partner) }}</span>
                                </b-form-group>
                              </b-td>
                            </template>
                          </b-tr>
                        </template>
                        <b-tr class="bg-info text-white">
                          <b-th colspan="2" class="text-right">Remaining Payment</b-th>
                          <b-td colspan="2">{{$parent.formatRupiah(remaining)}}</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Monitoring History">
          <template v-if="monitorHistory.length">
            <b-row>
              <b-col lg="8" md="10">
                <div class="timeline">
                  <div class="timeline-box" v-for="(value, index) in monitorHistory" :key="index">
                    <div class="timeline-head">
                      <h4 class="timeline__title">{{ value.tss_date | moment('LLL') }}</h4>
                      <b-button v-if="value.tss_image" size="sm" variant="outline-info" @click="setLightBox(value.tss_image)">
                        <i class="fas fa-image"></i>
                        See Photo
                      </b-button>
                      <b-button
                        class="ml-2 btn-icon"
                        variant="outline-warning"
                        v-b-tooltip.hover="'Edit Photo'"
                        v-if="value.tss_image"
                        @click="showUpdateImage(value.tss_image, value.tss_id)"
                        size="sm"
                      ><i class="fas fa-pencil-alt"></i>
                      </b-button>
                      <span>PIC: {{ value.ms_name}} - {{ value.tss_monitor_type == 'workshop' ? 'to Workshop' : 'to Location' }}</span>
                    </div>
                    <div class="timeline-body">
                      <p>{{ value.tss_result }}</p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <h3>Monitor History is Empty.</h3>
          </template>
        </b-tab>
      </b-tabs>
    </b-card>
    <div class="media-overlay" :class="showSurveyImg ? 'show' : ''" @click.stop="showSurveyImg = false">
      <div class="media-content" @click.stop>
        <div class="media-dialog parent-wrapper">
          <b-img :src="$parent.uploader(detailImg)" />
          <b-button size="sm" @click.stop="showSurveyImg = false" class="close-button btn" variant="danger"><i class="fas fa-times"></i></b-button>
        </div>
      </div>
    </div>
    <b-modal id="updateImage" title="Update Image" centered no-close-on-backdrop>
      <validation-observer v-slot="{ handleSubmit }" ref="VFormUpdateImage">
        <b-form @submit.prevent="handleSubmit(submitUpdateImage())">
          <b-form-row>
            <b-col md=12>
              <b-form-group label-for="monPhoto">
                <template #label>Photo</template>
                <Uploader v-model="dataModal.tss_image" :usePreview="true" type="survey_result"/>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="closeModal('updateImage')">Cancel</b-button>
        <b-button size="md" variant="info" @click="submitUpdateImage()">Update</b-button>
      </template>
    </b-modal>
  </section>
</template>
<script>
  import Gen from '@/libs/Gen'

  export default{
    props:{
      customerInformation: Object,
      followUpHistory: Array,
      surveyHistory: Array,
      remaining: Number,
      historyPayment: Array,
      monitorHistory: Array,
      detailImg: String
    },
    data() {
      return {
        showSurveyImg: false,
        dataModal: {}
      }
    },
    methods: {
      setPricePartner(data){
        const hasil = data['tcp_amount'] - data['tcp_amount_bu']
        data['tcp_amount_partner'] = hasil
      },
      setLightBox(src){
        this.detailImg = src
        this.showSurveyImg = true
      },
      updatePayment(){
        this.$refs.VFormUpdatePayment.validate().then(success => {
          if(!success) return

          this.dataModal.tp_code = this.customerInformation.tp_code

          this.$swal({
          title: 'Submit Payment...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.$parent.modulePage,
          { data: {type: 'update-payment', ...this.dataModal} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.$set(this, 'dataModal', {})
                this.$parent.apiGet()
              })
          }
        }).catch(() => {
            this.$set(this, 'dataModal', {})
            this.$parent.apiGet()
        })

        })
      },
      showUpdateImage(tss_image, tss_id){
        this.dataModal = {tss_image, tss_id}
        this.$bvModal.show('updateImage')
      },
      submitUpdateImage(){
        this.$refs.VFormUpdateImage.validate().then(success => {
          if(!success) return

          this.$swal({
            title: 'Update image...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
            '/do/' + this.$parent.modulePage,
            { data: {type: 'update-image-survey', ...this.dataModal} },
            'POST'
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                text: '',
                timer: 1500,
                icon: 'success'
              }).then(() => {
                this.$parent.apiGet()
                this.$bvModal.hide('updateImage')
              }).catch(() => {
                this.$parent.apiGet()
                this.$bvModal.hide('updateImage')
              })
            }
          })

        })
      },
      closeModal(id){
        this.$set(this, 'dataModal', {})
        this.$bvModal.hide(id)
      }
    }
  }
</script>
